import { useContext } from 'react';
import invariant from 'invariant';

import { get } from 'dpl/shared/utils/object';

import Context from '../utils/context';

export default function useFormContext(fieldName) {
  const formContext = useContext(Context);

  invariant(formContext, 'useFormContext invoked outside of form provider');

  if (!fieldName) {
    return formContext;
  }

  return {
    ...formContext,
    // data specific to the given name
    formFieldErrors: formContext.formErrors[fieldName],
    formFieldId: fieldName,
    formFieldValue: get(formContext.formState, fieldName)
  };
}
