import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'dpl/common/components/Icon';
import {
  COMMON_FORM_FIELD_CLASSES,
  FORM_FIELD_VARIANT_TYPES
} from 'dpl/shared/form/utils/constants';

import Label from './Label';
import ErrorWrapper from './ErrorWrapper';

const ARROW_SIZES = {
  [FORM_FIELD_VARIANT_TYPES.LARGE]: '32px',
  [FORM_FIELD_VARIANT_TYPES.REGULAR]: '24px'
};

const VARIANT_CLASSES = {
  [FORM_FIELD_VARIANT_TYPES.LARGE]: `${COMMON_FORM_FIELD_CLASSES} pv4 pv5-md pl4 pr10 f4 fw-medium`,
  [FORM_FIELD_VARIANT_TYPES.REGULAR]: `${COMMON_FORM_FIELD_CLASSES} pv3 ph4 font-16`
};

export default function SimpleSelect({
  disablePlaceholder,
  disabled,
  displayErrors,
  errors,
  id,
  label,
  name,
  onChange,
  options,
  placeholder,
  value,
  variant,
  testId,
  ...selectProps
}) {
  const arrowSize = ARROW_SIZES[variant];

  return (
    <div
      className="Select w-100 relative overflow-hidden"
      data-test-id={testId}
    >
      {label && <Label newDesignSystemStyles htmlFor={id} text={label} />}
      <ErrorWrapper errors={errors} isShown={displayErrors}>
        <div className="relative">
          <select
            data-test-id="form-select"
            disabled={disabled || !options || !options.length}
            className={classnames(VARIANT_CLASSES[variant], {
              disabled: !value
            })}
            id={id}
            name={name}
            onChange={onChange}
            value={value || ''}
            {...selectProps}
          >
            <option value="" disabled={disablePlaceholder}>
              {placeholder}
            </option>
            {
              // TODO: consolidate option value structure
              options.map(option => {
                if (typeof option === 'string') {
                  option = { value: option, title: option };
                }

                return (
                  <option
                    value={option.value || option.id}
                    key={option.value || option.id}
                  >
                    {option.title || option.name}
                  </option>
                );
              })
            }
          </select>
          <Icon
            className="mr2 z-3 pointer-events-none transform-center right-0"
            height={arrowSize}
            name="fetch-chevron-down"
            width={arrowSize}
          />
        </div>
      </ErrorWrapper>
    </div>
  );
}

SimpleSelect.propTypes = {
  testId: PropTypes.string,
  disablePlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
  displayErrors: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.node),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.instanceOf(Date)
        ]),
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        title: PropTypes.string
      }),
      PropTypes.string
    ])
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** One of: "regular" or "large". */
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES))
};

SimpleSelect.defaultProps = {
  testId: 'form-simple-select',
  disabled: false,
  disablePlaceholder: false,
  displayErrors: false,
  errors: [],
  id: null,
  label: null,
  name: null,
  onChange: null,
  placeholder: 'Select',
  value: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR
};
