import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';

import SimpleSelect from './SimpleSelect';

export default function Select({ name, onChange, ...selectProps }) {
  const {
    formFieldErrors,
    formFieldId,
    formFieldValue,
    handleFormFieldChange
  } = useFormContext(name);

  return (
    <SimpleSelect
      errors={formFieldErrors}
      id={formFieldId}
      name={name}
      onChange={onChange || handleFormFieldChange}
      value={formFieldValue}
      {...selectProps}
    />
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

Select.defaultProps = {
  onChange: null
};
