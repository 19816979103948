import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatPhoneNumber, unformatPhoneNumber } from 'dpl/shared/form/utils';
import { FORM_FIELD_VARIANT_TYPES } from 'dpl/shared/form/utils/constants';

import Label from './Label';
import ErrorWrapper from './ErrorWrapper';
import { VARIANT_CLASSES } from './SimpleInput';

export default function SimpleTelephoneInput({
  autoComplete,
  className,
  displayErrors,
  errors,
  fullWidth,
  id,
  inputClassName,
  label,
  name,
  onChange,
  required,
  value,
  variant,
  testId,
  labelClassName,
  newDesignSystemStyles,
  ...inputProps
}) {
  const inputValue = value || '';

  function handleChange(e) {
    const phoneNumber = unformatPhoneNumber(e.target.value);

    if (phoneNumber !== inputValue) {
      onChange(phoneNumber);
    }
  }

  return (
    <div
      className={classnames('TelephoneInput', className)}
      data-test-id={testId}
    >
      {label && (
        <Label
          className={labelClassName}
          htmlFor={id}
          required={required}
          text={label}
          newDesignSystemStyles={newDesignSystemStyles}
        />
      )}
      <ErrorWrapper
        isShown={displayErrors}
        errors={errors}
        newDesignSystemStyles={newDesignSystemStyles}
      >
        <input
          {...inputProps}
          autoComplete={autoComplete}
          className={classnames(
            'TelephoneInput__input',
            VARIANT_CLASSES[variant],
            {
              'w-auto': !fullWidth,
              'b--rubber':
                newDesignSystemStyles &&
                errors.length > 0 &&
                inputValue.length > 0
            },
            inputClassName
          )}
          id={id}
          name={name}
          onChange={handleChange}
          type="tel"
          value={formatPhoneNumber(inputValue)}
        />
      </ErrorWrapper>
    </div>
  );
}

SimpleTelephoneInput.propTypes = {
  testId: PropTypes.string,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  displayErrors: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.node),
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  /** One of: "regular" or "large". */
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES)),
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  newDesignSystemStyles: PropTypes.bool
};

SimpleTelephoneInput.defaultProps = {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values
  autoComplete: null,
  testId: 'form-simple-telephone-input',
  className: null,
  displayErrors: false,
  errors: [],
  fullWidth: false,
  id: null,
  label: null,
  required: false,
  value: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR,
  inputClassName: null,
  labelClassName: null,
  newDesignSystemStyles: false
};
