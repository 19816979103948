import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';

import SimpleTelephoneInput from './SimpleTelephoneInput';

export default function TelephoneInput({
  name,
  onChange,
  newDesignSystemStyles,
  ...inputProps
}) {
  const { formFieldErrors, formFieldId, formFieldValue, addToFormState } =
    useFormContext(name);

  function handleChange(value) {
    addToFormState(name, value);
  }

  return (
    <SimpleTelephoneInput
      errors={formFieldErrors}
      id={formFieldId}
      name={name}
      onChange={onChange || handleChange}
      value={formFieldValue}
      newDesignSystemStyles={newDesignSystemStyles}
      {...inputProps}
    />
  );
}

TelephoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  newDesignSystemStyles: PropTypes.bool
};

TelephoneInput.defaultProps = {
  onChange: null,
  newDesignSystemStyles: false
};
