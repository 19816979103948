import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { COMMON_LABEL_CLASSES } from 'dpl/shared/form/utils/constants';

export default function Label({
  htmlFor,
  text,
  className,
  newDesignSystemStyles
}) {
  const LABEL_CLASSNAMES = newDesignSystemStyles
    ? 'fl mb2 font-12 secondary fw-normal'
    : COMMON_LABEL_CLASSES;
  return (
    <label className={classnames('Label', { dn: !text })} htmlFor={htmlFor}>
      <span
        className={classnames('db', {
          mb1: !newDesignSystemStyles
        })}
      >
        <span className="flex align-items-center">
          <span className="flex-auto">
            <span
              className={classnames('Label__text', LABEL_CLASSNAMES, className)}
            >
              {text}
            </span>
          </span>
        </span>
      </span>
    </label>
  );
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  newDesignSystemStyles: PropTypes.bool
};

Label.defaultProps = {
  htmlFor: null,
  text: null,
  className: '',
  newDesignSystemStyles: false
};
